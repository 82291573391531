import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout";
import TextWrapper from "../components/styles/TextWrapper";
import SEO from "../components/seo";
export const _frontmatter = {
  "title": "Uses",
  "path": "/uses",
  "date": "05-07-2020"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <SEO title="Uses" mdxType="SEO" />
  <TextWrapper mdxType="TextWrapper">
    <h2>What do I use and reccomend</h2>
    <ul>
      <li>MacBook Pro (13-inch, 2020) i5, 16Gb ram</li>
      <li>Editor: VsCode</li>
      <li>Terminal: Oh-my-zsh</li>
      <li>Snippets: Dash</li>
      <li>Distraction blocking: Freedom app</li>
      <li>Note taking: Notion</li>
      <li>Password manager: BitWarden</li>
      <li>Mind Mapping: XMind</li>
      <li>Gif generation: Annotate</li>
      <li>Productivity: Alfred</li>
      <li>Host: Netlify</li>
      <li>Domain managment: Route53, Google Domains</li>
      <li>Cloud provider: GCP, firebase</li>
      <li>Storage: Orico NVME enclosure + Intel Nvme 1TB 🐇</li>
    </ul>
  </TextWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      